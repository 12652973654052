import React from 'react'
import Link from 'next/link'
import { useTrans } from 'hooks'
import { getIcon } from 'utils/icons'
import { MenuItemType } from 'utils/types'
import { LogoNoBordo, Phone } from 'components/atoms/Icons'
import { WContainer } from 'components/atoms'
import styles from './Footer.module.sass'
import { useSelector } from 'react-redux'
import useGTM from '../../../hooks/useGTM'

interface Props {
  className?: string
}

const Footer = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const { menu } = useSelector((state) => state.menu)
  const { trackingGAevent } = useGTM()
  const footerMenu = menu?.find((item) => item.key === 'footer')
  const socialMenu = menu?.find((item) => item.key === 'social')

  const getSocialName = (socialUrl: string) => {
    return socialUrl.includes('facebook')
      ? 'facebook'
      : socialUrl.includes('instagram')
      ? 'instagram'
      : 'youtube'
  }

  // FAKE
  // const socialMenu: MenuItemType = {
  //   pk: 2,
  //   children: [
  //     { pk: 21, style: 'facebook', url: 'https://www.facebook.com/mellin' },
  //     { pk: 22, style: 'instagram', url: 'https://www.instagram.com/mellin_official/' },
  //     { pk: 23, style: 'youtube', url: 'https://www.youtube.com/user/MellinOfficial' },
  //   ]

  // }
  // const footerMenu: MenuItemType = {
  //   pk: 1,
  //   children: [
  //     { pk: 11, name: 'Mellin Manifesto', url: '#' },
  //     { pk: 12, name: 'Eventi e concorsi', url: '#' },
  //     { pk: 13, style: 'separator' },
  //     { pk: 14, name: 'FAQ', url: '#' },
  //     { pk: 15, name: 'Mappa del sito', url: '#' },
  //     { pk: 16, name: 'Privacy Policy', url: '#' },
  //     { pk: 17, name: 'Cookie policy', url: '#' },
  //     { pk: 18, name: 'Informativa sulla privacy', url: '#' },
  //     { pk: 19, style: 'separator' },
  //     { pk: 20, name: 'Servizio consumatori', url: '#' },
  //     { pk: 21, name: 'Codice etico', url: '#' },
  //     { pk: 22, name: 'Modello 231', url: '#' },
  //     { pk: 23, name: 'Marketing responsabile', url: '#' },
  //   ]
  // }

  return (
    <footer className={`${styles.footer} ${className}`}>
      <WContainer>
        <div className={styles.top}>
          <div className={styles.logoWrap}>
            <Link legacyBehavior href="/">
              <a className={styles.logo}>
                <LogoNoBordo />
              </a>
            </Link>
          </div>
          <div className={styles.socialMenu}>
            {socialMenu?.children?.map((item) => (
              <a
                href={item.url}
                target="_blank"
                rel="noopener norefferer"
                className={styles.socialItem}
                key={item.pk}
                onClick={() => {
                  trackingGAevent('3', 'footer', getSocialName(item.url), 'click')
                  trackingGAevent('12', 'social_network', getSocialName(item.url), 'click')
                }}
              >
                {getIcon(item.style)}
              </a>
            ))}
          </div>
          <div className={styles.info}>
            <p>{t('Mellin Spa, Via Farini 41, 20159 Milano')}</p>
            <p>{t('p.iva n° 03531280968')}</p>
          </div>
          <div className={styles.customerService}>
            <p className={styles.customerServiceTitle}>{t('Careline servizio Consumatori')}</p>
            <div className={styles.phone}>
              <span className={styles.phoneIcon}>
                <Phone />
              </span>
              <span>{t('800 73 73 40')}</span>
            </div>
          </div>
        </div>
        <div className={styles.bCorp}>
            {t(
              'Note sulla Certificazione: La “Certificazione B Corporation” è un marchio che viene concesso in licenza da B Lab, ente privato no profit, alle aziende che, come la nostra, hanno superato con successo il B Impact Assessment (“BIA”) e soddisfano quindi i requisiti richiesti da B Lab in termini di performance sociale e ambientale, responsabilità e trasparenza. Si specifica che B Lab non è un organismo di valutazione della conformità ai sensi del Regolamento (UE) n. 765/2008 o un organismo di normazione nazionale, europeo o internazionale ai sensi del Regolamento (UE) n. 1025/2012. I criteri del BIA sono distinti e autonomi rispetto agli standard armonizzati risultanti dalle norme ISO o di altri organismi di normazione e non sono ratificati da parte di istituzioni pubbliche nazionali o europee.'
            )}
          </div>
    
        <div className={styles.containerMenuFooter}>
          <div className={styles.disclaimer}>
            <p>
              {t(
                'Importante: Il latte materno è l’alimento ideale per il bambino nella prima infanzia ed è raccomandato come componente lattea in una dieta equilibrata anche dopo l’anno di vita. Il latte di proseguimento e crescita Mellin è da intendersi all’interno di una dieta varia ed equilibrata e uno stile di vita sano, seguendo le indicazioni del pediatra.'
              )}
            </p>
          </div>
          <div className={styles.menu}>
            {footerMenu?.children?.map((item) =>
              item.style === 'separator' ? (
                <div className={styles.menuSeparator} key={item.pk} />
              ) : item.style === 'cookiebot' ? (
                <a className={styles.menuItem} href="javascript: Cookiebot.show()" key={item.pk}>
                  {item.name}
                </a>
              ) : item.key === 'servizio-consumatori' ? (
                <a
                  className={styles.menuItem}
                  href={`mailto:${t('servizioconsumatori.mellin@danone.com')}`}
                  key={item.pk}
                >
                  {item.name}
                </a>
              ) : item.url ? (
                <Link legacyBehavior href={item.url} key={item.pk}>
                  <a
                    className={styles.menuItem}
                    onClick={() => trackingGAevent('3', 'footer', item.name, 'click')}
                  >
                    {item.name}
                  </a>
                </Link>
              ) : (
                ''
              )
            )}
          </div>
        </div>
      </WContainer>
    </footer>
  )
}

export default Footer
